import React from "react";
import mapboxgl from "mapbox-gl";
import pulsingDot from "./components/pulsingDot";

class Map extends React.Component {
    mapRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            dot_size: 200,
            innerHeight: window.innerHeight,
            lng: 0,
            lat: 0,
            zoom: 1,
            region: {},
            mapbox: 'pk.eyJ1Ijoiam9yZGFubHlzOTUiLCJhIjoiY2pwdGY1cnJvMDBybTQycHVkNDVzMGptNyJ9.D6g_-JlWkYhZMt0GMUxTrA'
        };
    }

    componentDidUpdate(prevProps, prevState) {
        var _this = this;

    }

    componentDidMount() {
        var _this = this;


        window.addEventListener("resize", function () {
            _this.setState({ innerHeight: window.innerHeight });
        });

        mapboxgl.accessToken = this.state.mapbox;

        const { lng, lat, zoom } = this.state;
        const map = new mapboxgl.Map({
            container: this.mapRef.current,
            style: "mapbox://styles/mapbox/" + this.props.mapStyle,
            center: [lng, lat],
            zoom,
            attributionControl: false,
            interactive: false
        });
        let _pulsingDot = new pulsingDot(map)

        map.on("style.load", function(){

            map.addImage('pulsing-dot', _pulsingDot, { pixelRatio: 2 });
    
            map.addSource('dot-point', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': [
                        {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [0, -50] // icon position [lng, lat]
                            }
                        }
                    ]
                }
            });
            
            map.addLayer({
                'id': 'layer-with-pulsing-dot',
                'type': 'symbol',
                'source': 'dot-point',
                'layout': {
                    'icon-image': 'pulsing-dot'
                }
            });
        })




    }

    render() {
        const { lng, lat, zoom } = this.state;

        return (
            <div className="flywhere-map">
                <div
                    ref={this.mapRef}
                    style={{
                        height: this.state.innerHeight,
                    }}
                />
            </div>
        );
    }
}

export default Map;
