import Map from './map'
import React from 'react'

class FourOFour extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render(){
        return (
            <React.Fragment>
                <div className="overlay">
                    <div className="overlay--content">
                        <h1>404</h1>
                        <p>Oops! We couldn't find this page</p>
                        <a className="overlay--action-button" href="/">Return To Home</a>
                    </div>
                    <aside className="overlay--bg"></aside>
                    </div>
                <Map
                    mapStyle="dark-v10"
                />
            </React.Fragment>
        );

    }

}

export default FourOFour